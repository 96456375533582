import { useQuery } from '@tanstack/react-query';
import ApprovalSetup from '../ApprovalSetup';
import ApprovalsTable from './ApprovalsTable';
import ApprovalsSerive from '../approvalsService';

type Props = {
  feature: FeatureType;
};

const ApprovalsTab = ({ feature }: Props) => {
  const { isLoading, data, isFetched, isFetching } = useQuery({
    queryKey: ['approvals_list', feature],
    queryFn: async () => {
      const data = await ApprovalsSerive.getApprovalStructures(feature);
      return data;
    },
    retry: false,
  });

  if (!isFetched && isLoading) {
    return <div className='text-center'>Loading...</div>;
  }

  if (!data || !data.length) {
    return <ApprovalSetup feature={feature} />;
  }

  return <ApprovalsTable feature={feature} data={data} isFetching={isFetching} />;
};

export default ApprovalsTab;
