import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DropdownInput } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { selectOwningCustomers, sendToast } from '../../../../store/slices/main/mainSlice';

interface BeneficiaryDetailsProps {
  disabled: boolean;
  onSaveAndProceed?: () => void;
}

interface Option {
  value: string;
  label: string;
}

export const BeneficiaryDetails = ({ disabled, onSaveAndProceed }: BeneficiaryDetailsProps) => {
  const {
    handleSubmit,
    formState: { isValid },
  } = useForm<any>();
  const [options, setOptions] = useState<Option[]>([]);
  const owningCustomers = useSelector(selectOwningCustomers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setOptions(
      owningCustomers.map((customer) => ({
        value: customer.id?.toString() || '',
        label: customer.customerShortName,
      })),
    );
  }, []);

  const saveBeneficiaryDetails = () => {
    if (onSaveAndProceed) {
      onSaveAndProceed();
    }
  };

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const showErrorToast = () => {
    showToast('error', 'client.field.validation.error', 'client.field.validation.error.detail');
  };

  return (
    <div className='relative'>
      {disabled && <div className='absolute inset-0 bg-white opacity-70 cursor-not-allowed z-10' />}
      <div className={(disabled ? 'opacity-50	' : '') + 'rounded-c8 shadow-c bg-white p-8'}>
        <div className='flex justify-between flex-wrap'>
          <div>
            <div className='text-sm-bold'>
              <Translate value='beneficiary.title' />
            </div>
            <div className='text-neutral-3'>
              <Translate value='beneficiary.description' />
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(saveBeneficiaryDetails)}>
          <div className='mt-8 flex gap-4 text-neutral-2 text-sm-medium'>
            <DropdownInput
              className='min-w-[250px]'
              placeholder='Search by keywords'
              options={options}
              label='Select from existing beneficiaries'
              isRequired
            />
          </div>

          <div className='flex items-center justify-center mt-10 gap-5'>
            <Button
              type='submit'
              severity='info'
              className='w-48 inline-flex items-center justify-center'
              onClick={() => {
                // if (handleStepperValidation) {
                //   handleStepperValidation(RequestQuickQuoteType.QuoteAmounCurrency, isValid);
                // }
                if (!isValid) {
                  showErrorToast();
                  return;
                }
              }}
            >
              <Translate value='save_and_proceed' />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
