import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { MultiSelect } from 'primereact/multiselect';
import { Controller } from 'react-hook-form';
import { translateWithValues } from '../../i18n/translate';
import ErrorIcon from '../ErrorIcon';
import './MultiSelect.scss';

interface MultiSelectProps {
  id?: string;
  label?: string;
  placeholder?: string;
  ariaDescribedBy?: string;
  isRequired?: boolean;
  className?: string;
  value?: string[]; // MultiSelect expects an array of values
  name?: string;
  control?: any;
  error?: any;
  options?: any;
  rules?: any;
  disabled?: boolean;
  onChange?: (e: any) => void;
  itemTemplate?: (option: any) => React.ReactNode;
}

const MultiSelectInput = ({
  label,
  id,
  isRequired,
  ariaDescribedBy,
  placeholder,
  className,
  value,
  rules,
  name,
  error,
  options,
  control,
  disabled,
  onChange,
  itemTemplate,
}: MultiSelectProps) => {
  const errorMessage =
    error &&
    (error.type === 'required'
      ? `${translateWithValues(label)} ${translateWithValues('is_required')}`
      : error.message);
  return (
    <div className={`flex flex-col gap-1 ${className || ''}`}>
      {label && (
        <label
          htmlFor={id}
          className='whitespace-nowrap overflow-hidden text-ellipsis inline-block'
        >
          {translateWithValues(label)} {isRequired ? <span className='text-error-1'>*</span> : ''}
        </label>
      )}
      <IconField iconPosition='right'>
        {control && name ? (
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field }) => (
              <MultiSelect
                className='w-full'
                filter
                id={id}
                tooltip={error && errorMessage}
                name={name}
                disabled={disabled}
                value={field.value || []} // MultiSelect expects an array
                onChange={(e) => field.onChange(e.value)}
                options={options}
                placeholder={placeholder && translateWithValues(placeholder)}
                invalid={!!error}
                itemTemplate={itemTemplate}
              />
            )}
          />
        ) : (
          <MultiSelect
            className='w-full'
            itemTemplate={itemTemplate}
            id={id}
            name={name}
            value={value || []} // Ensure value is an array
            aria-describedby={ariaDescribedBy}
            placeholder={placeholder && translateWithValues(placeholder)}
            options={options}
            onChange={onChange}
            invalid={!!error}
          />
        )}

        {!!error && (
          <InputIcon className='mr-[23px]'>
            <ErrorIcon isFilled={false} size={16} withBackground={false} />
          </InputIcon>
        )}
      </IconField>
    </div>
  );
};

export default MultiSelectInput;
