import apiClient from '../../utils/apiClient';
import { handleApiResponse } from '../../utils/helper';
import { bankMockData, rateRequestMockData } from './paymentMockData';

const ROOT = '/api/pricing-management/';

const CUSTOMER_MANAGEMENT = '/api/customer-management';
const ORDER_MANAGEMENT = '/api/order-management';

const PaymentService = {
  async getRateRequests(): Promise<RateRequest[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(rateRequestMockData);
      }, 1000);
    });
  },

  async getQuickQuote(data: any): Promise<QuickQouteProps | 'Error'> {
    try {
      const response = await apiClient.post(`${ROOT}quick-quote`, data);
      return handleApiResponse<QuickQouteProps>(response);
    } catch (error) {
      return 'Error';
    }
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(quickQuoteMock);
    //   }, 4000);
    // });
  },

  async getQuoteByMargin(data: any): Promise<QuickQouteProps> {
    try {
      const response = await apiClient.post(`${ROOT}quotes`, data);
      return handleApiResponse<QuickQouteProps>(response);
    } catch (error) {
      console.error('Error creating quote', error);
      throw error;
    }
  },

  async recalculateQuote(data: any): Promise<QuickQouteProps | 'Error'> {
    try {
      const response = await apiClient.post(`${ROOT}quotes/recalculate`, data);
      return handleApiResponse<any>(response);
    } catch (error) {
      return 'Error';
    }
  },

  async getBranches(): Promise<Branch[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(bankMockData);
      }, 1000);
    });
  },
  async createNewCustomer(payload: CustomerCreationForm): Promise<Client> {
    const response = await apiClient.post(`${CUSTOMER_MANAGEMENT}/customers-details`, payload);
    return handleApiResponse(response);
  },

  async createPayment(payload: CreatePaymentPayload) {
    try {
      const response = await apiClient.post(`${ORDER_MANAGEMENT}/orders`, payload);
      return handleApiResponse(response);
    } catch (error) {
      console.error('Error createing payment', error);
      return null;
    }
  },
};

export default PaymentService;
