import { useState } from 'react';
import { Confirm, WarningIcon } from '../../../../components';
import { Translate } from '../../../../i18n/translate';
import { useAppDispatch } from '../../../../store';
import { sendToast } from '../../../../store/slices/main/mainSlice';
import UserService from '../../userService';

const ActionModals = ({
  confirmationState,
  currentActionUser,
  pageParams,
  setConfirmationState,
  hideConfirmPopup,
  getUsers,
  getUser,
}: {
  confirmationState: ConfirmationState;
  currentActionUser: User | null;
  pageParams?: PageParams;
  getUser?: () => Promise<void>;
  getUsers?: (pageParams: PageParams) => Promise<void>;
  hideConfirmPopup: (key: keyof ConfirmationState) => void;
  setConfirmationState: React.Dispatch<React.SetStateAction<ConfirmationState>>;
}) => {
  const [isResetMFAInProgress, setIsResetMFAInProgress] = useState(false);
  const [isResetPasswordInProgress, setIsResetPasswordInProgress] = useState(false);
  const [isBlockUserInProgress, setIsBlockUserInProgress] = useState(false);
  const [isUnBlockUserInProgress, setIsUnBlockUserInProgress] = useState(false);
  const [logOutChecked, setLogOutChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const resetMFA = async () => {
    if (currentActionUser?.id) {
      setIsResetMFAInProgress(true);
      const { status, data } = await UserService.resetMFA(currentActionUser?.id);
      setConfirmationState((prevState) => ({
        ...prevState,
        resetMFA: false,
        confirmResetMFA: true,
      }));
      if (status != 200 || !data.success) {
        showToast('error', 'error', 'failed.to.reset.MFA.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      } else {
        showToast('success', 'success', 'success.to.reset.MFA.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      }
    }
    setIsResetMFAInProgress(false);
  };

  const resetPassword = async () => {
    if (currentActionUser?.id && currentActionUser.id) {
      setIsResetPasswordInProgress(true);
      const { status, data } = await UserService.resetPassword(currentActionUser.id);
      if (status != 200 || !data.success) {
        showToast('error', 'error', 'failed.to.resetpassword.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      } else {
        showToast('success', 'success', 'success.to.resetpassword.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      }
    }
    hideConfirmPopup('resetPassword');
    setIsResetPasswordInProgress(false);
  };

  const blockUser = async () => {
    if (currentActionUser?.id) {
      setIsBlockUserInProgress(true);
      const { status, data } = await UserService.blockUser(currentActionUser?.id);
      setConfirmationState((prevState) => ({
        ...prevState,
        blockUser: false,
        confirmBlockUser: true,
      }));
      if (status != 200 || !data.success) {
        showToast('error', 'error', 'failed.to.block.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      } else {
        showToast('success', 'success', 'success.to.block.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      }
      setIsBlockUserInProgress(false);
      if (getUsers && pageParams) getUsers(pageParams);
      if (getUser) {
        getUser();
      }
    }
  };

  const unBlockUser = async () => {
    if (currentActionUser?.id) {
      setIsUnBlockUserInProgress(true);
      const { status, data } = await UserService.unBlockUser(currentActionUser?.id);
      setConfirmationState((prevState) => ({
        ...prevState,
        unblockUser: false,
        confirmUnblockUser: true,
      }));
      if (status != 200 || !data.success) {
        showToast('error', 'error', 'failed.to.unblock.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      } else {
        showToast('success', 'success', 'success.to.unblock.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      }
      setIsUnBlockUserInProgress(false);
      if (getUsers && pageParams) getUsers(pageParams);
      if (getUser) {
        getUser();
      }
    }
  };

  const deleteUser = async () => {
    if (currentActionUser?.id) {
      const { status, data } = await UserService.delete(currentActionUser?.id);
      setConfirmationState((prevState) => ({
        ...prevState,
        deleteUser: false,
        confirmDeleteUser: true,
      }));

      if (status != 200 || !data.success) {
        showToast('error', 'error', 'failed.to.delete.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      } else {
        showToast('success', 'success', 'success.to.delete.user', {
          firstName: currentActionUser.userIdentity.firstName,
          lastName: currentActionUser.userIdentity.lastName,
        });
      }

      if (getUsers && pageParams) getUsers(pageParams);
      if (getUser) {
        getUser();
      }
    }
  };

  return (
    <>
      <Confirm
        headerIcon={<WarningIcon />}
        title={'reset.password.title'}
        message='reset.password.description'
        onCancel={() => hideConfirmPopup('resetPassword')}
        onConfirm={resetPassword}
        confirmLabel={
          <span className='relative'>
            <span className={isResetPasswordInProgress ? 'invisible' : ''}>
              {<Translate value='confirm' />}
            </span>
            {isResetPasswordInProgress && (
              <span className='absolute inset-0 w-full h-full'>
                <Translate value='sending.status' />
              </span>
            )}
          </span>
        }
        visible={confirmationState.resetPassword}
        checkboxLabel={<Translate value='logout.all.devices' />}
        checkboxChecked={logOutChecked}
        onCheckboxChange={setLogOutChecked}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='reset.mfa.title'
        message='reset.mfa.description'
        onCancel={() => hideConfirmPopup('resetMFA')}
        onConfirm={resetMFA}
        confirmLabel={
          <span className='relative'>
            <span className={isResetMFAInProgress ? 'invisible' : ''}>
              {<Translate value='confirm' />}
            </span>
            {isResetMFAInProgress && (
              <span className='absolute inset-0 w-full h-full'>
                <Translate value='sending.status' />
              </span>
            )}
          </span>
        }
        visible={confirmationState.resetMFA}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='unblock.user.success.title'
        i18nParameters={{ user: currentActionUser?.userIdentity.firstName ?? '' }}
        message='unblock.user.description'
        onCancel={() => hideConfirmPopup('unblockUser')}
        onConfirm={unBlockUser}
        confirmLabel={
          <span className='relative'>
            <span className={isUnBlockUserInProgress ? 'invisible' : ''}>
              <Translate value='confirm' />
            </span>
            {isUnBlockUserInProgress && (
              <span className='absolute inset-0 w-full h-full'>
                {' '}
                <Translate value='sending.status' />
              </span>
            )}
          </span>
        }
        visible={confirmationState.unblockUser}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        title='block.user.title'
        i18nParameters={{ user: currentActionUser?.userIdentity.firstName ?? '' }}
        message='block.user.description'
        onCancel={() => hideConfirmPopup('blockUser')}
        onConfirm={blockUser}
        confirmLabel={
          <span className='relative'>
            <span className={isBlockUserInProgress ? 'invisible' : ''}>
              <Translate value='confirm' />
            </span>
            {isBlockUserInProgress && (
              <span className='absolute inset-0 w-full h-full'>
                <Translate value='sending.status' />
              </span>
            )}
          </span>
        }
        visible={confirmationState.blockUser}
      />

      <Confirm
        headerIcon={<WarningIcon />}
        confirmButtonSeverify='danger'
        title='delete.account.title'
        i18nParameters={{ user: currentActionUser?.userIdentity.firstName ?? '' }}
        message='delete.account.description'
        onCancel={() => hideConfirmPopup('deleteUser')}
        onConfirm={deleteUser}
        visible={confirmationState.deleteUser}
      />
    </>
  );
};

export default ActionModals;
